import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Alert, Box, Button, ButtonBase, Popover, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Spacer } from '@eatclub-apps/ec-component-library';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Modal } from '../Modal';
import { Heading } from '../Heading';
import {
  createCCSetupIntentAction,
  lookupPaymentMethodNameAction,
  savePaymentDetailsAction,
} from '../../actions/settlementsAction';
import { devLog } from '../../utils';
import { Text } from '../Text';

const StripeInput = React.forwardRef((props, ref) => {
  const { component: Component, disabled, ...rest } = props;

  //   const elementRef = useRef();

  useImperativeHandle(ref, () => ({
    // focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      //   onReady={(element) => (elementRef.current = element)}
      options={{
        disableLink: true,
        disabled,
        placeholder: '',
        showIcon: true,
        style: {
          base: {
            fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif'",
            fontSize: '16px',
          },
        },
      }}
      {...rest}
    />
  );
});

export const AddNewCardModal = ({ isOpen, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);

  const [cardName, setCardName] = useState('');

  const savePaymentDetails = useSelector((state) => state.savePaymentDetails);

  const isSaving = isCreatingPaymentMethod || savePaymentDetails.pending;

  const [cardNameError, setCardNameError] = useState(null);
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCCVError, setCardCCVError] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setCardName('');
    setCardNameError(null);
    setCardNumberError(null);
    setCardExpiryError(null);
    setCardCCVError(null);
  }, [isOpen]);

  useEffect(() => {
    if (savePaymentDetails.success) {
      onClose();
    }
  }, [savePaymentDetails.success, onClose]);

  const handleConfirm = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setErrorMessage(null);

    try {
      let valid = true;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (cardName.length === 0) {
        valid = false;
        setCardNameError('This field is required');
      } else {
        setCardNameError(null);
      }

      if (cardNumberError) {
        valid = false;
      }

      if (cardExpiryError) {
        valid = false;
      }

      if (cardCCVError) {
        valid = false;
      }

      if (valid) {
        setIsCreatingPaymentMethod(true);

        const {
          response: {
            data: { createCCSetupIntent },
          },
        } = await createCCSetupIntentAction(
          cardName,
          stripe,
          elements.getElement(CardNumberElement),
        );

        const { secret } = JSON.parse(createCCSetupIntent);
        const result = await stripe.confirmCardSetup(secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardName,
            },
          },
        });

        if (result.error) {
          throw new Error(result.error.message);
        }

        const paymentMethodId = result.setupIntent.payment_method;
        const paymentMethodType = result.setupIntent.payment_method_types[0];

        const {
          response: {
            data: { lookupPaymentMethodName },
          },
        } = await lookupPaymentMethodNameAction(paymentMethodId);

        const { paymentMethodName } = JSON.parse(lookupPaymentMethodName);

        await dispatch(
          savePaymentDetailsAction(paymentMethodId, paymentMethodName, paymentMethodType),
        );
      }
    } catch (err) {
      devLog('error', 'AddNewCardModal - handleConfirm', err);
      setErrorMessage(`${err}`);
    } finally {
      setIsCreatingPaymentMethod(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading='Add a new card'
      confirmLabel='Add a new card'
      onConfirm={handleConfirm}
      disabled={!stripe}
      inputStyle={{ maxWidth: '540px' }}
      isLoading={isSaving}
    >
      <Spacer direction='vertical' gap='m'>
        <Spacer direction='vertical' gap='s'>
          <span>Card name</span>
          <TextField
            disabled={isSaving}
            error={cardNameError}
            // helperText={cardNameError}
            onChange={(e) => setCardName(e.target.value)}
            required
            size='small'
            value={cardName}
          />
        </Spacer>
        <Spacer direction='vertical' gap='s'>
          <span>Card number</span>
          <TextField
            InputLabelProps={{ shrink: true }}
            InputProps={{
              disableUnderline: true,
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
                onChange: (e) => setCardNumberError(e.error?.message || null),
                disabled: isSaving,
              },
            }}
            disabled={isSaving}
            error={cardNumberError}
            // helperText={cardNumberError}
            required
            size='small'
          />
        </Spacer>
        <Spacer gap='l'>
          <Spacer direction='vertical' style={{ flexGrow: 1 }}>
            <span>Expiry date</span>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  onChange: (e) => setCardExpiryError(e.error?.message || null),
                  disabled: isSaving,
                },
              }}
              disabled={isSaving}
              error={cardExpiryError}
              //   helperText={cardExpiryError}
              required
              size='small'
              style={{ flexGrow: 1 }}
            />
          </Spacer>
          <Spacer direction='vertical' style={{ flexGrow: 1 }}>
            <span>CVC</span>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  onChange: (e) => setCardCCVError(e.error?.message || null),
                  disabled: isSaving,
                },
              }}
              disabled={isSaving}
              error={cardCCVError}
              //   helperText={cardCCVError}
              required
              size='small'
            />
          </Spacer>
        </Spacer>
      </Spacer>

      {errorMessage && (
        <Alert severity='error' style={{ marginTop: '20px' }}>
          {errorMessage}
        </Alert>
      )}

      <StripeBanner />
    </Modal>
  );
};

const StripeBanner = () => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const ref = useRef();

  return (
    <Box
      ref={ref}
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        background: '#F2EFEE',
        borderRadius: '12px',
        flexWrap: 'wrap',
        marginTop: '40px',
        padding: '12px',
      }}
    >
      <Box>
        <svg
          width='21'
          height='27'
          viewBox='0 0 21 27'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 18.6723C1 19.3982 1.08697 22.1246 1.07336 24.3193C1.06609 25.4922 2.00826 26.499 3.1812 26.499H7.31186H15.6636C16.7681 26.499 17.6633 25.6036 17.6633 24.499V23.7218C17.6633 22.5927 18.1683 11.8555 17.1584 11.8555H12.1089H2.76681C1.79074 11.8555 1 12.6467 1 13.6228V18.6723Z'
            stroke='#313131'
          />
          <path
            d='M10.8226 17.7693C10.6077 18.3583 10.2918 18.7824 9.9538 19.0156C9.63102 19.2383 9.27686 19.2987 8.89293 19.1585C8.13375 18.8814 7.6612 17.835 8.07678 16.6964C8.49235 15.5579 9.52788 15.0619 10.2871 15.339C10.6486 15.4709 10.8793 15.756 10.9795 16.1777C11.0831 16.6136 11.0378 17.1798 10.8226 17.7693Z'
            stroke='#313131'
          />
          <path
            d='M8.57422 18.9263V22.2084C8.57422 22.6268 8.91333 22.9659 9.33164 22.9659V22.9659C9.74996 22.9659 10.0891 22.6268 10.0891 22.2084V18.9263'
            stroke='#313131'
          />
          <path
            d='M2.76723 11.6039C2.51475 8.15344 3.37314 1 8.82659 1C15.6434 1 15.3909 7.05939 15.3909 11.3515'
            stroke='#313131'
          />
          <path
            d='M17.4236 8.04996C17.9162 7.66591 18.1409 7.13923 18.2522 6.54564C18.3282 6.14051 18.3925 5.73385 18.3925 5.32178'
            stroke='#313131'
            strokeLinecap='round'
          />
          <path
            d='M18.2881 9.39171C18.6352 9.28758 18.9995 9.0373 19.257 8.77979'
            stroke='#313131'
            strokeLinecap='round'
          />
          <path
            d='M19.1526 10.8908C19.3253 10.8538 19.7293 10.6807 19.8155 10.5083'
            stroke='#313131'
            strokeLinecap='round'
          />
          <path
            d='M5.49994 11.5C5.33327 9 6.2 4 9 4C12.5 4 12.4999 7.5 12.4999 11.5'
            stroke='#313131'
          />
        </svg>
      </Box>
      <Box>
        Payment data stored and encrypted by{' '}
        <ButtonBase
          onClick={() => setPopoverAnchorEl(ref.current)}
          disableRipple
          disableTouchRipple
          style={{ textDecoration: 'underline', fontSize: 'inherit', lineHeight: 'inherit' }}
        >
          Stripe
        </ButtonBase>
        .
      </Box>

      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{ padding: '28px 24px', maxWidth: ref.current?.clientWidth }}>
          <Spacer>
            <svg
              width='21'
              height='27'
              viewBox='0 0 21 27'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 18.6723C1 19.3982 1.08697 22.1246 1.07336 24.3193C1.06609 25.4922 2.00826 26.499 3.1812 26.499H7.31186H15.6636C16.7681 26.499 17.6633 25.6036 17.6633 24.499V23.7218C17.6633 22.5927 18.1683 11.8555 17.1584 11.8555H12.1089H2.76681C1.79074 11.8555 1 12.6467 1 13.6228V18.6723Z'
                stroke='#313131'
              />
              <path
                d='M10.8226 17.7693C10.6077 18.3583 10.2918 18.7824 9.9538 19.0156C9.63102 19.2383 9.27686 19.2987 8.89293 19.1585C8.13375 18.8814 7.6612 17.835 8.07678 16.6964C8.49235 15.5579 9.52788 15.0619 10.2871 15.339C10.6486 15.4709 10.8793 15.756 10.9795 16.1777C11.0831 16.6136 11.0378 17.1798 10.8226 17.7693Z'
                stroke='#313131'
              />
              <path
                d='M8.57422 18.9263V22.2084C8.57422 22.6268 8.91333 22.9659 9.33164 22.9659V22.9659C9.74996 22.9659 10.0891 22.6268 10.0891 22.2084V18.9263'
                stroke='#313131'
              />
              <path
                d='M2.76723 11.6039C2.51475 8.15344 3.37314 1 8.82659 1C15.6434 1 15.3909 7.05939 15.3909 11.3515'
                stroke='#313131'
              />
              <path
                d='M17.4236 8.04996C17.9162 7.66591 18.1409 7.13923 18.2522 6.54564C18.3282 6.14051 18.3925 5.73385 18.3925 5.32178'
                stroke='#313131'
                strokeLinecap='round'
              />
              <path
                d='M18.2881 9.39171C18.6352 9.28758 18.9995 9.0373 19.257 8.77979'
                stroke='#313131'
                strokeLinecap='round'
              />
              <path
                d='M19.1526 10.8908C19.3253 10.8538 19.7293 10.6807 19.8155 10.5083'
                stroke='#313131'
                strokeLinecap='round'
              />
              <path
                d='M5.49994 11.5C5.33327 9 6.2 4 9 4C12.5 4 12.4999 7.5 12.4999 11.5'
                stroke='#313131'
              />
            </svg>
            <Heading size='4'>Data security</Heading>
          </Spacer>
          <Text style={{ marginTop: '20px' }}>
            We take security seriously and follow the highest industry standards when it comes to
            payment details. All payment details are safely encrypted and stored by our payment
            partner Stripe.
          </Text>
          <Text style={{ marginTop: '20px' }}>
            Stripe is run under the same regulations as Australian banks and uses the strongest
            encryption technology in the market to ensure your sensitve data is kept safe.{' '}
            <a href='https://stripe.com/' style={{ color: 'inherit' }}>
              Visit stripe’s website
            </a>
          </Text>

          <Box style={{ marginTop: '24px' }}>
            <Button variant='outlined' color='secondary' onClick={() => setPopoverAnchorEl(null)}>
              Ok, got it
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
