import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, FormHelperText, TextField } from '@mui/material';
import { Button, Box } from '@eatclub-apps/ec-component-library';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { publicApiCall } from '../../actions/actions';
import { COLORS } from '../../EatClubTheme';
import { ssoMagicLink } from '../../graphql/queries';
import useStyles from './CustomSignInStyles';
import { AppLogo } from '../../constants/AppLogo';
import { devLog, useQuery } from '../../utils';
import { isEmpty } from '../../utils/helpers';

const CustomSignIn = ({ authSignedIn }) => {
  const history = useHistory();
  const classes = useStyles();

  const queryRef = useRef(useQuery());
  const params = queryRef.current;
  // const loginFrom = queryRef.current.get('origin');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signinLoading, setSigninLoading] = useState(false);
  const [signinError, setSigninError] = useState('');

  const [magicLinkMessage, setMagicLinkMessage] = useState('');
  const [magicLinkError, setMagicLinkError] = useState('');
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // force home page on log in
  useEffect(() => {
    // Bypass if we're in the middle of an sso login
    // const params = new URLSearchParams(window.location.search);
    const ssoToken = params.get('ssoToken');
    if (ssoToken) {
      return;
    }

    // Workaround for facebook integration - redirect to prod
    if (
      import.meta.env.VITE_MODE !== 'production' &&
      window.location.href.includes('fbmarketplace')
    ) {
      window.location.href =
        import.meta.env.VITE_PRODUCTION_URL + document.location.pathname + document.location.search;
      return;
    }

    // failsafe if staff mode lingered in partner login
    if (localStorage.getItem('mode') === 'staff') {
      localStorage.removeItem('mode');

      // reload for the authenticator to use the correct user pool config
      window.location.reload();
      return;
    }

    // Remove last part of url, and redirect back to it on login. Include query params
    // NOTE: redirectTo is only for internal routes
    const { pathname } = window.location;

    if (!isEmpty(pathname) && pathname !== '/' && pathname !== '?' && !params.get('redirectTo')) {
      const redirectUri =
        pathname + (params.toString() ? encodeURIComponent(`?${params.toString()}`) : '');

      // Retain some params, like origin, while adding the redirect uri
      const search = `?${
        params.get('origin') ? `origin=${params.get('origin')}&` : ''
      }redirectTo=${redirectUri}`;

      history.replace({
        pathname: '/',
        search,
      });
    }
  }, []);

  // Set title and header
  useEffect(() => {
    const pageTitle = `Login | ${import.meta.env.VITE_WEBSITE_NAME} Partners`;
    window.document.title = pageTitle;
  }, []);

  const styles = {
    paper: {
      maxWidth: '500px',
      margin: '80px auto 0',
      padding: '40px',
    },
    form: {
      margin: '0 auto',
    },
    textfield: {
      marginBottom: '12px',
      label: {
        fontSize: '14px',
        marginBottom: '2px',
      },
    },
    logo: {
      width: '200px',
      display: 'block',
      margin: '0 auto',
      marginBottom: '24px',
    },
    button: {
      button: {
        backgroundColor: COLORS.BRAND_PRIMARY,
        borderRadius: '100px',
        boxSizing: 'border-box',
        color: '#ffffff',
        padding: '8px 24px',
        fontSize: '16px',
        height: '44px',
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: '28px',
        fontWeight: '500',
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return;
    }

    setSigninLoading(true);

    try {
      const user = await Auth.signIn(email.trim(), password);
      devLog('success', 'user', user);
    } catch (error) {
      devLog('error', 'user', error);
      setSigninError(error.message);
    } finally {
      setSigninLoading(false);
    }
  };

  if (authSignedIn) {
    return null;
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      // noinspection JSIgnoredPromiseFromCall
      handleSubmit(e);
    }
  };

  const handleEmailLogin = async () => {
    if (!isEmpty(email)) {
      setMagicLinkLoading(true);
      setMagicLinkMessage('');
      setMagicLinkError('');

      const response = await publicApiCall(ssoMagicLink, { email });

      setMagicLinkLoading(false);

      // Handle errors (e.g. invalid email)
      if (!isEmpty(response?.error)) {
        setMagicLinkMessage('');
        setMagicLinkError(response?.error?.message);
        return;
      }

      setMagicLinkMessage(`A login link has been sent to ${email}`);
    }
  };

  return (
    <Box
      style={{
        borderRadius: '12px',
        maxWidth: '500px',
        margin: '80px auto 0',
      }}
    >
      <Box
        style={{
          borderRadius: '12px',
          maxWidth: '500px',
          // margin: '80px auto 0',
          padding: '40px',
          border: `1px solid ${COLORS.CLOUD}`,
          boxShadow: '0px 4px 8px rgba(151, 151, 151, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <form
          noValidate
          onSubmit={handleSubmit}
          style={styles.form}
          id='loginForm'
          className={classes.form}
        >
          <Box style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <Box style={{ width: '200px' }}>
              <AppLogo />
            </Box>
          </Box>
          {/* <img */}
          {/*  src={AppLogo} */}
          {/*  alt={`${import.meta.env.VITE_WEBSITE_NAME} Logo `} */}
          {/*  className={classes.logo} */}
          {/* /> */}

          <TextField
            autoFocus
            style={styles.textfield}
            // disabled={props.user.loginPending ? true : false}
            // error={formErrors.email ? true : false}
            id='email'
            name='email'
            label='Email'
            variant='outlined'
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputProps={{ inputMode: 'email', autoCapitalize: false }}
            onKeyDown={keyPress}
          />
          <TextField
            style={styles.textfield}
            // disabled={props.user.loginPending ? true : false}
            // error={formErrors.password ? true : false}
            id='password'
            name='password'
            label='Password'
            variant='outlined'
            type='password'
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={keyPress}
          />

          {Boolean(signinError) && (
            <FormHelperText error style={{ marginBottom: '1rem', marginTop: 0 }}>
              {signinError}
            </FormHelperText>
          )}

          <Button
            form='loginForm'
            disabled={signinLoading}
            color={COLORS.BRAND_PRIMARY}
            style={styles.button}
            type='custom'
            fullWidth
            onClick={handleSubmit}
          >
            {signinLoading ? <CircularProgress size={26} color='inherit' /> : 'Log in'}
          </Button>
        </form>

        <Box style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
          {magicLinkLoading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            <Button
              type='text'
              style={{
                button: {
                  fontSize: '15px',
                  textDecoration: 'underline',
                },
              }}
              onClick={handleEmailLogin}
              disabled={magicLinkLoading}
            >
              Or email me a login link
            </Button>
          )}
          {magicLinkMessage && <Box style={{ fontSize: '14px' }}>{magicLinkMessage}</Box>}
          {magicLinkError && <Box style={{ fontSize: '14px', color: 'red' }}>{magicLinkError}</Box>}
        </Box>

        <Box style={{ display: 'flex', gap: '4px', fontSize: '12px' }}>
          <span>Need help?</span>
          <Button
            type='text'
            style={{
              button: {
                textDecoration: 'underline',
              },
              link: {
                color: 'inherit',
              },
            }}
            href='mailto:hello@eatclub.com.au'
          >
            Contact support
          </Button>
        </Box>
      </Box>

      <>
        {window.location.search.includes('fbmarketplace') && (
          <Box
            style={{
              textAlign: 'center',
              fontSize: '15px',
              color: COLORS.SMOKE,
              marginTop: '16px',
            }}
          >
            Integrate your EatClub account with Facebook
          </Box>
        )}
      </>
    </Box>
  );
};

export default CustomSignIn;
